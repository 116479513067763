<template>
    <div class="cdiv">
        <div class="header flex">
            <div>
                <el-image class="weiziiconst" :src="require('../assets/img/dingwei.png')" fit="cover"></el-image>
            </div>
            <div class="pleft">当前位置：<span>首页</span><span>>火车票改签</span></div>
        </div>
        <div class="hc">
            <el-form size="small" :inline="true" ref="form" :model="form" label-width="100px" class="formstyleyd">
                <el-row :gutter="20" class="flex-search" align="middle">
                    <el-col :span="6">
                        <el-form-item label="日期：" prop="date1">
                            <el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd"
                                v-model="form.senddate" style="width: 100%"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="出发：" prop="sendcity">
                            <!-- <el-input class="inputstyle heightstyle" v-model="form.sendcity" clearable></el-input> -->
                            <el-select class="inputstyle heightstyle" v-model="form.sendcity" filterable remote
                                reserve-keyword placeholder="请输入关键词"
                                :remote-method="(value) => remoteMethod(value, 'startStationOptions')"
                                :loading="loading">
                                <el-option v-for="item in startStationOptions" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="1">
                        <el-image class="weiziicon" @click="gotoqiehuantrain"
                            :src="require('../assets/img/wagnfan.png')" fit="cover"></el-image>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="到达：" prop="arrivalcity">
                            <!-- <el-input class="inputstyle heightstyle" v-model="form.arrivalcity" clearable></el-input> -->
                            <el-select class="inputstyle heightstyle" v-model="form.arrivalcity" filterable remote
                                reserve-keyword placeholder="请输入关键词"
                                :remote-method="(value) => remoteMethod(value, 'endStationOptions')" :loading="loading">
                                <el-option v-for="item in endStationOptions" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3">
                        <el-button class="querybtninfo" size="small" @click="querytrain">查询</el-button>
                    </el-col>
                </el-row>
            </el-form>
            <el-form size="small" :inline="true" ref="form" :model="form" label-width="80px">
                <el-form-item>
                    <el-col :span="7">
                        <el-button :class="trainType === '' ? 'querybtn' : ''" class="btnstyles mleftstylesinfo"
                            @click="trainType = ''">全部</el-button>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="7">
                        <el-button :class="trainType === 1 ? 'querybtn' : ''" class="btnstyles mleftstylesinfo"
                            @click="trainType = 1">高铁</el-button>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="7">
                        <el-button @click="trainType = 2" :class="trainType === 2 ? 'querybtn' : ''"
                            class="btnstyles mleftstylesinfo">动车</el-button>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="7">
                        <el-button @click="trainType = 3" :class="trainType === 3 ? 'querybtn' : ''"
                            class="btnstyles mleftstylesinfo">直达</el-button>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="7">
                        <el-button @click="trainType = 4" :class="trainType === 4 ? 'querybtn' : ''"
                            class="btnstyles mleftstylesinfo">特快</el-button>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="7">
                        <el-button @click="trainType = 5" :class="trainType === 5 ? 'querybtn' : ''"
                            class="btnstyles mleftstylesinfo">快速</el-button>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="7">
                        <el-button @click="trainType = 99" :class="trainType === 99 ? 'querybtn' : ''"
                            class="btnstyles mleftstylesinfo">其他</el-button>
                    </el-col>
                </el-form-item>
            </el-form>
        </div>
        <div class="tcontent flex" v-for="(item, index) in liststrains" :key="index" v-if="trainType==''||item.trainsType==trainType">
            <div class="tcontentleft flex">
                <div class="scheci commonmtop">
                    <div class="sccdate">{{ item.fromTime }}</div>
                    <div class="scdiv flex">
                        <div>
                            <el-image class="weiziicon" :src="require('../assets/img/shi.png')" fit="cover"></el-image>
                        </div>
                        <div class="startzhan">{{ item.fromStation }}</div>
                    </div>
                </div>
                <div class="haoshi commonmtop">
                    <div class="cxinfo">{{ item.trainCode }}</div>
                    <div>
                        <el-image class="weiziicon" :src="require('../assets/img/jiantou.png')" fit="cover" v-if="item.begin==1"></el-image>
                    </div>
                    <div class="sleft">
                        {{formateHour(item.runTime)}}小时{{ item.runTime.slice(3, 5) }}分
                    </div>
                </div>
                <div class="endcheci commonmtop">
                    <div class="sccdate">{{ item.toTime }}</div>
                    <div class="scdiv flex">
                        <div>
                            <el-image class="weiziicon" :src="require('../assets/img/zhong.png')"
                                fit="cover" v-if="item.end==1"></el-image>
                        </div>
                        <div class="startzhan">{{ item.toStation }}</div>
                    </div>
                </div>
            </div>
            <div class="tcontentright flex">
                <div class="checileixing">
                    <div class="zwleixing cmtopzw" v-for="(item1, index1) in item.Seats" :key="index1">
                        <div>
                            {{ item1.seatTypeName }}
                        </div>

                        <div class="colorstyle">￥{{ item1.ticketPrice }}</div>
                        <div>{{ item1.leftTicketNum }}张</div>
                        <div>
                            <!-- <el-button class="btnstyle" size="mini" v-if="item1.leftTicketNum == 0" disabled="disabled"
                                style="background-color: #585858">预定</el-button> -->
                            <el-button class="btnstyle" size="mini" @click="
                                forwardyuding(
                                    item.fromStation,
                                    item.fromTime,
                                    item.trainCode,
                                    item.runTime,
                                    item.toStation,
                                    item.toTime,
                                    item1.ticketPrice,
                                    item.Seats,
                                    item1.seatTypeName
                                )
                                ">{{item1.leftTicketNum == 0?"申请":"改签"}}</el-button>
                        </div>
                    </div>
                </div>

                <!-- <div class="checimoney">
                    <div class="zwleixing cmtopzw colorstyle" v-for="(item2, index2) in item.Seats" :key="index2">
                    </div>
                   
                </div>
                <div class="checizuowei">
                    <div class="zwleixing cmtopzw" v-for="(item3, index3) in item.Seats" :key="index3">
                        
                    </div>
                  
                </div>
                <div class="operbtntyd">
                    <div class="zwleixing mtb" v-for="(item3, index3) in item.Seats" :key="index3">
                       
                    </div>
                    
                </div> -->
            </div>
        </div>
        <el-empty class="empty-content" v-if="liststrains.length == 0"
            :description="loading ? '正在加载' : '暂无数据'"></el-empty>

        <!--<div class="pager flex">-->
        <!--<div class="shouye">-->
        <!--<el-button class="sybtn">首页</el-button>-->
        <!--</div>-->
        <!--<div class="btnnodiv">-->
        <!--<el-button class="btnno defaultbtn">1</el-button>-->
        <!--</div>-->
        <!--<div class="btnnodiv">-->
        <!--<el-button class="btnno">2</el-button>-->
        <!--</div>-->
        <!--<div class="btnnodiv">-->
        <!--<el-button class="btnno">3</el-button>-->
        <!--</div>-->
        <!--<div class="btnnodiv">-->
        <!--<el-button class="btnno">4</el-button>-->
        <!--</div>-->
        <!--<div class="btnnodiv">-->
        <!--<el-button class="btnno">5</el-button>-->
        <!--</div>-->
        <!--<div class="btnnodiv">-->
        <!--<el-button class="btnno">6</el-button>-->
        <!--</div>-->
        <!--<div class="shouye mleftmargin">-->
        <!--<el-button class="sybtn">末页</el-button>-->
        <!--</div>-->
        <!--</div>-->
        <div class="copyright">工业和信息化部备案管理系统网站 豫B1-20080191-28</div>
        
        <el-dialog title="改签原因" v-show="dialogcancelVisible" :visible="dialogcancelVisible" width="550px"
                   top="80px" :before-close="handleCloseLoginCancel">
          <el-card class="box-card">
            <el-form ref="form" :rules="rules" :model="gqform" label-width="80px">
              <div>
                <div class="remarkinfo">
                  <el-input type="textarea" v-model="gqform.remark_tkorder" show-word-limit resize="none" :rows="5"
                            class="txtarea" placeholder="这里显示改签原因"></el-input>
                </div>
              </div>
        
              <div class="btnstylesinfo">
                <el-button class="login-btn clearbtn" @click="gotoclosebtn"> 关闭 </el-button>
                <el-button class="login-btn" type="primary" @click="confirmCancelBtn"
                           style="background-color: rgba(66, 177, 52, 1)" :loading="loading">确定</el-button>
              </div>
            </el-form>
          </el-card>
        </el-dialog>
    </div>
</template>

<script>
import Cookie from 'js-cookie';
import ElImage from "element-ui/packages/image/src/main";
import ElButton from "element-ui/packages/button/src/button";
import { queryLeftTicket, querytrainlike,updategaiqianordstatus } from "../api/index";
export default {
    components: {
        ElButton,
        ElImage,
    },
    data() {
        return {
            allListstrain: [],
            liststrains: {},
            trainType: "", // 车次类型（高铁 = 1, 动车 = 2,直达 = 3,特快 = 4,快速 = 5,其他 = 6）
            form: {
                senddate: "",
                sendcity: "",
                arrivalcity: "",
            },
            rules: {
                mobilephone: [
                    { required: true, message: "请输入手机号", trigger: "blur" },
                    { pattern: /^1[3-9]\d{9}$/, message: "手机号格式不正确", trigger: "blur" },
                ],

                pwd: [{ required: true, message: "请输入密码", trigger: "blur" }],
            },
            startStationOptions: [],
            endStationOptions: [],
            loading: false,
            isLoadingData: false,
            dialogcancelVisible:false,
            gqform:{
                user_id:"",
                topg_ids:"",
                liushui_no:"",
                departure_station:"",
                arrival_station:"",
                send_date:"",
                train_number:"",
                arrival_date:"",
                runtime:"",
                order_money:"",
                remark_tkorder:""
            }
        };
    },
    // watch: {
    //     trainType() {
    //         this.getTrainList();
    //     },
    // },
    computed: {
        // liststrains() {
        //     return this.allListstrain.filter((item) => {
        //         return item.trainsType === this.trainType;
        //     });
        // },
    },
    methods: {
        formateHour(runTime){
            if(runTime && runTime[0] === '0'){
                return runTime.substr(1, 1)
            }
            return runTime.substr(0, 2)
        },
        debounce(func, wait, immediate) {
            let timeout;
            return (function () {
                let context = this,
                    args = arguments;
                let later = function () {
                    timeout = null;
                    if (!immediate) func.apply(context, args);
                };
                let callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) func.apply(context, args);
            })();
        },
        remoteMethod(query, type = "startStationOptions") {
            this.debounce(() => {
                if (query !== "") {
                    this.loading = true;
                    querytrainlike({ initial: query }).then((res) => {
                        // console.log("%c Line:273 🍩", "color:#4fff4B", res);
                        const { status, data } = res;
                        if (status == 200) {
                            console.log("%c Line:277 🥐", "color:#b03734", data, type);
                            this[type] = data.data[query].map((item) => {
                                return { value: item.station_name, label: item.station_name };
                            });
                        }
                        this.loading = false;
                    });
                    // setTimeout(() => {
                    //     this.loading = false;
                    //     this.options = this.list.filter((item) => {
                    //         return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
                    //     });
                    // });
                } else {
                    this.options = [];
                }
            }, 500);
        },
        //查询火车余票
        querytrain() {
            const senddatestr = this.form.senddate;
            const sendcitystr = this.form.sendcity;
            const arrivalcitystr = this.form.arrivalcity;
            if ("" == senddatestr || null == senddatestr) {
                this.$message.warning("请选择日期!");
                return;
            }
            if ("" == sendcitystr || null == sendcitystr) {
                this.$message.warning("请输入出发站!");
                return;
            }
            if ("" == arrivalcitystr || null == arrivalcitystr) {
                this.$message.warning("请输入到达站!");
                return;
            }
            this.liststrains=[];
            const params = {
                fromStation: sendcitystr,
                toStation: arrivalcitystr,
                fromDate: senddatestr,
            };
            this.loading = true;
            const isSucessRes = (res) => {
                const data = JSON.parse(res.data);
                if (data.data) {
                    return data.data;
                }
                this.$message.error("查询失败!");
                return false;
            };
            queryLeftTicket(params).then((res) => {
                this.loading = false;
                // const data = isSucessRes(res);
                // if (data) {
                    this.liststrains=res.data.data;
                    // this.allListstrain = res.data.data;
                    // this.getTrainList()
                // }
            });
        },
        // getTrainList() {
        //     this.liststrains = this.allListstrain.filter((item) => {
        //         console.log("%c Line:284 🍩 item", "color:#ea7e5c", item);

        //         console.log("%c Line:376 🍇", "color:#b03734", item.trainsType, this.trainType);
        //         return item.trainType === this.trainType
        //     });
        //     console.log("%c Line:375 🥔 this.liststrains", "color:#7f2b82", this.liststrains);
        // },
        //点击后进行切换
        gotoqiehuantrain() {
            if (this.form.sendcity && this.form.arrivalcity) {
                const c1 = this.form.sendcity;
                const c2 = this.form.arrivalcity;
                this.form.sendcity = c2;
                this.form.arrivalcity = c1;
            } else {
                this.$message.warning("请输入出发到达站!");
            }
        },
        //转向预定申请
        forwardyuding(fromStation, fromTime, trainCode, runTime, toStation, toTime, ticketPrice, seats,seatTypeName) {
            const id = this.$route.query.orderId;
            const topg_id = this.$route.query.ids;
            const userinfo = JSON.parse(Cookie.get('userinfo'));
            var form={
                user_id:userinfo.id,
                topg_ids:String(topg_id),
                liushui_no:id,
                danwei_name:userinfo.gs_name,
                departure_station:fromStation,
                arrival_station:toStation,
                send_date:this.form.senddate,
                train_number:trainCode,
                arrival_date:toTime,
                runtime:runTime,
                order_money:ticketPrice,
                remark_tkorder:""
            }
            this.gqform=form;
            this.dialogcancelVisible=true;
            
        },
        //改签窗口关闭
        handleCloseLoginCancel(){
          this.dialogcancelVisible=false;
        },
        //关闭改签窗口
        gotoclosebtn(){
          this.dialogcancelVisible=false;
        },
        //确认改签
        async confirmCancelBtn(){
            if(!this.gqform.remark_tkorder){
              this.$message.error('请输入取消原因');
              return;
            }
            try {
              const id = this.$route.query.orderId;
              var form=this.gqform
              const data = await updategaiqianordstatus(form);
              if (data.data.code != 1) {
                this.$message({
                  type: "error",
                  message: data.data.message,
                });
              } else {
                  this.$message.success("改签成功");
                  this.dialogcancelVisible = false;
                  this.$router.push({
                      path:"/trainorderdetails",
                      query:{
                          orderId:id
                      }
                  })
              }
            } catch (error) {
              this.$message.error("改签失败");
            }
        },
    },
    name: "trainyuding-right",
};
</script>

<style scoped lang="less">
.flex-search {
    display: flex;
    margin-bottom: 18px;
    align-items: center;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.flex-search /deep/ .el-form-item {
    display: flex;
    align-items: center;
    margin-bottom: 0 !important;
}

.defaultbtn {
    background-color: #42b134;
    color: #ffffff;
}

.mleftstylesinfo {
    margin-left: 1.5vw;
}

.mtt {
    margin-top: -0.5vh;
}

.mtb {
    margin-top: 0.5vh;
}

.weiziiconst {
    width: 24px;
    height: 24px;
}

.btnstyles {
    background-color: rgba(255, 255, 255, 1);
    color: #000000;
    border-radius: 6px;
    border: 1px solid #000000;
    width: 5vw;
    /*height:4vh;*/
    /*line-height:4vh;*/
    /*vertical-align: middle;*/
}

.wfimg {
    margin-left: 1vw;
    margin-top: 20px;
}

.formstyleyd .inputstyle {
    width: 154px;
}

.labelstyle .el-form-item__label {
    font-family: MicrosoftYaHei;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
}

.cdiv {
    /* margin-top:30px; */
}

.copyright {
    width: calc(88% - 0px - 10px) !important;
    font-size: 12px;
    text-align: center;
    margin-top: 5vh;
    color: #b6b6b6;
}

.mleftmargin {
    margin-left: 1vw;
}

.pager {
    margin-top: 5vh;
    margin-left: 0vw;
    width: calc(88% - 0px - 10px) !important;
    display: flex;
    justify-content: center;
}

.empty-content {
    width: calc(88% - 0px - 10px) !important;
}

.shouye {}

.sybtn {
    width: 100px;
    height: 35px;
    border: 1px solid #666666;
    text-align: center;
}

.btnnodiv {
    margin-left: 1vw;
}

.btnno {
    width: 50px;
    height: 35px;
    border: 1px solid #666666;
    text-align: center;
}

.commonmtop {
    margin-top: 2vh;
}

.btnmargin {
    margin-top: 1vh;
}

.btnstyle {
    background-color: #42b134;
    color: #ffffff;
    border-radius: 5px;
}

.operbtntyd {
    margin-left: 50px;
}

.colorstyle {
    color: #f49200;
    font-weight: bold;
    font-family: MicrosoftYaHei;
    font-size: 14px;
}

.checileixing {
    width: 350px;
    /* text-align: center */
    display: flex;
    flex-direction: column;
}

.checileixing>div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.checileixing>div>div {
    width: 80px;
    text-align: center;
}

.checimoney {
    /* margin-left: 40px;kkkkkk */
    width: 80px;
    text-align: center;
}

.checizuowei {
    width: 80px;
    text-align: center;
}

.zwleixing {}

.cmtopzw {
    margin-top: 0.5vh;
}

.cxinfo {
    // margin-left: 55px;
}

.sleft {
    // margin-left: 40px;
}

.scheci {
    margin-left: 20px;
    width: 20%;
}

.startzhan {
    margin-left: 0.5vw;
    margin-top: 2px;
}

.scdiv {
    margin-top: 2vh;
}

.sccdate {
    font-size: 20px;
    font-weight: bolder;
    margin-left: 1vw;
}

.haoshi {
    // margin-left: 120px;
    width: 60%;
    text-align: center;
}

.endcheci {
    // margin-left: 120px;
    width: 20%;
}

.tcontentleft {
    width: calc(59% - 0px - 0px) !important;
}

.tcontentright {
    width: calc(40% - 0px - 0px) !important;
    justify-content: center;
    display: flex;
    font-size: 14px;
}

.tcontent {
    background-color: #f1f1f1;
    height: 190px;
    margin-top: 2vh;
    /* padding-top: 4vh; */
    align-items: center;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.mleftd {}

.querybtn {
    background-color: #42b134;
    color: #ffffff;
    border-radius: 5px;
    /* margin-left:2vw; */
}

.querybtninfo {
    background-color: #42b134;
    color: #ffffff;
    border-radius: 5px;
    padding-left: 35px;
    padding-right: 35px;
}

.formstyleyd {
    padding-top: 1vh;
    margin-left: 0.3vw;
}

.container {}

.header {
    font-size: 12px;
}

.flex {
    display: flex;
}

.weiziicon {}

.pleft {
    margin-left: 0.5vw;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
}

.hc {
    background-color: #f1f1f1;
    height: 130px;
    // margin-top: 2vh;3.2vh
    padding-top: 2vh;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

button:hover {
    background-color: #42b134;
    color: #ffffff;
}
.btnstylesinfo {
  margin-top: 30px;
  justify-content: center;
  display: flex;
  text-align: center;
}
</style>
