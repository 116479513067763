import http from '../utils/request';

const apiBaseUrl = ''
// 请求注册公司部门数据
export const getComdeptdata = (params) => {
  return http.post('/common/comdeptdata', params)
}
// 请求首页数据
export const getData = (params) => {
  return http.get('/home/getData', params)
}

// 查询航班城市信息
export const getData233 = () => {
  return http.get('/jeecg-boot/system/airportInfo/queryAll')
}

// 查询航空公司信息
export const getAirlineCompanyList = () => {
  return http.get('/jeecg-boot/system/airlineCompany/queryAll')
}

// 查询航班列表
export const getInternationalTicketList = (params) => {
  return http.get(`/jeecg-boot/ticketquery/internationalTicketQuery/list?${params}`)
}

// 会员注册
export const register = (params) => {
  return http.post('/user/register', params)
}
//会员登录
export const denglu = (params) => {
    return http.post('/user/login', params)
}
//查询火车余票
export const queryLeftTicket = (params) => {
    return http.post('/traintickets/queryticket', params)
}

// 获取验证码
export const smsCode = (params) => {
  return http.post('/common/sendsms',params)
}
//会员忘记密码
export const forgetpwd = (params) => {
    return http.post('/user/resetpwd', params)
}
//查询轮播图
export const queryslider = (params) => {
    return http.post('/common/carousel', params)
}
//分页查询公告
export const querygg = (params) => {
    return http.post('/common/noticelist',params)
}
//查询网站信息
export const querywebsite=() => {
    return http.get(`${apiBaseUrl}/website/websiteinfo/querywebsite`)
}
//根据手机号查询会员信息
export const querycompanybyphone = (params) => {
    return http.post(`${apiBaseUrl}/member/memberinfo/querycompanybyphone`,params)
}
//根据手机号查询公司是否存在
export const querycompanyexitsinfo = (params) => {
    return http.post(`${apiBaseUrl}/member/memberinfo/querycompanyexist`,params)
}

//根据ID查询公告详细信息
export const queryggid=(params) => {
    return http.post('/common/noticedetail',params)
}
//根据ID修改公告信息
export const updategonggao = (params) => {
    return http.post(`${apiBaseUrl}/gonggao/gonggaoinfo/updategg`,params)
}
//根据ID查询会员信息
export const querymemByIdInfo=(params) => {
    return http.get(`${apiBaseUrl}/member/memberinfo/querymemById?${params}`)
}
//修改会员信息
export const updatememinfo = (params) => {
    return http.post(`${apiBaseUrl}/member/memberinfo/updatememinfo`,params)
}
//根据会员手机号修改密码
export const updatepwdByPhoneInfo = (params) => {
    return http.post(`${apiBaseUrl}/member/memberinfo/updateByPhone`,params)
}
//根据手机号查询会员信息
export const querymemphone = (params) => {
    return http.post(`${apiBaseUrl}/member/memberinfo/querymemById`,params)
}
//解绑手机
export const updateunbindPhone = (params) => {
    return http.post(`${apiBaseUrl}/member/memberinfo/unbindphone`,params)
}
// 登录
export const memlogininfo = (params) => {
  return http.get(`/jeecg-boot/company/empInfo/queryByPhonePwd?${params}`)
}

// 获取历史记录
export const queryByMemAccount = (memAccount) => {
  return http.get(`/jeecg-boot/member/searchTicketHistory/queryByMemAccount?memAccount=${memAccount}`)
}

// 清空历史记录
export const deleteByAccount = (memAccount) => {
  return http.delete(`/jeecg-boot/member/searchTicketHistory/deleteByAccount?memAccount=${memAccount}`)
}

export const queryEmp = () => {
  return new Promise((resolve) => {
    resolve({
      msg:'error'
    })
  })
}

// 火车票下拉 
export const querytrainlike = (data) => {
  return http.post('/traintickets/getalltrainStation',data)
}

// 身份证识别

// /api/train/orderinfo/cardidshibie
export const cardidshibie = (data) => {
  return http.post('/common/uploadIdCard',data)
}
// 文本识别
export const textrecognition = (data) => {
  return http.post('/common/textrecognition',data)
}



// 常用人 公司人分页
export const queryPassengerlist = (data) => {
  return http.post('/common/personnellist',data)
}
//常用人 公司人不分页
export const queryPassengerlistnopage = (data) => {
    return http.post(apiBaseUrl + '/passenger/frequentlyinfo/querylist',data)
}
//查询待审核
export const queryPassengerlistdaishenhe = (data) => {
    return http.post(apiBaseUrl + '/passenger/frequentlyinfo/querydaishenhepage',data)
}

export const getweizishibie = (data) => {
  return http.post(apiBaseUrl + '/train/orderinfo/getweizishibie',data)
}


export const updateorderstatus = (data) => {
  return http.post('/traintickets/traincancel',data)
}


export const updategaiqianordstatus = (data) => {
  return http.post('/traintickets/trainrebook',data)
}


export const updatetpordstatus = (data) => {
  return http.post('/traintickets/trainrefund',data)
}


export const delorder = (data) => {
  return http.post(apiBaseUrl + '/train/orderinfo/delorder',data)
}


///api/train/orderpassenger/updateorderpg
export const updateorderpg = (data) => {
  return http.post('/traintickets/editpassenger',data)
}


export const orderdelpassenger = (data) => {
  return http.post('/common/delpassenger',data)
}

//api/train/orderinfo/addinfo
export const addOrderInfo = (data) => {
  return http.post('/traintickets/subreservation',data)
}
export const addpassengerinfo = (params) => {
  return http.post('/common/addfrequentcaller', params)
}

export const updatepassengerinfo= (data) => {
    return http.post(apiBaseUrl + '/passenger/frequentlyinfo/updatepg',data)
}

export const querypassengerbyId = (data) => {
    return http.post(apiBaseUrl + '/passenger/frequentlyinfo/querypassengerbyId',data)
}
///api/train/orderinfo/querytorderpage 
export const querytorderpage = (data) => {
  return http.post('/traintickets/orderlist',data)
}

//api/train/orderinfo/queryorderbyId  
export const queryorderbyId = (data) => {
  return http.post('/traintickets/orderdetail', data)
}

///api/airticket/xingcheng/addinfo
export const addjipiaoinfo = (data) => {
  return http.post('airlineticket/subreservation',data)
}

// api/airticket/orderinfo/querytorderpage 
export const queryAirtorderpage = (data) => {
  return http.post('/airlineticket/orderlist',data)

}

// api/airticket/orderinfo/queryorderbyId 
export const queryairtorderbyId = (data) => {
  return http.post('/airlineticket/orderdetail', data)
}


///api/airticket/orderinfo/updatetpordstatus
export const updatetairpordstatus = (data) => {
  return http.post('/airlineticket/trainrefund',data)
}

///api/airticket/orderinfo/delorder
export const delairorder = (data) => {
  return http.post(apiBaseUrl + '/airticket/orderinfo/delorder',data)
}

///api/airticket/orderinfo/updateorderstatus
export const updateairorderstatus = (data) => {
  return http.post('/airlineticket/traincancel',data)
}

//airticket/orderinfo/updategaiqianordstatus
export const updateairgaiqianordstatus = (data) => {
  return http.post('/airlineticket/trainrebook',data)
}

//airticket/orderpassenger/updateorderpg
export const updateairorderpg = (data) => {
  return http.post(apiBaseUrl + '/airticket/orderpassenger/updateorderpg',data)
}

//查询账户明细
export const queryminxi = (data) => {
  return http.post(apiBaseUrl + '/account/mingxiinfo/queryminxi',data)
}

//查询常旅客信息
export const querypage = (data) => {
  return http.post('/common/personnellist',data)
}

//删除常旅客信息
export const delPassenger = (data) => {
  return http.post('/common/unbindcomperson',data)
}

//添加常旅客信息
export const addpage = (data) => {
  return http.post(apiBaseUrl + '/passenger/frequentlyinfo/addinfo',data)
}
//审核常旅客信息
export const updateshenhe = (data) => {
    return http.post('/common/toexamine',data)
}
//查询部门管理
export const queryDept = (data) => {
    return http.post('/common/deptlist',data)
}

//删除部门管理
export const delDept  = (data) => {
    return http.post('/common/deldept',data)
}

//查询结算单
export const queryAccountmingxi = (data) => {
    return http.post(apiBaseUrl + '/account/mingxi/querypage',data)
}
///api/train/orderinfo/updategaiqianordstatus
// export const updategaiqianordstatus = (data) => {
//   return http.post(apiBaseUrl + '/train/orderinfo/updategaiqianordstatus',data)
// }


//xiaoxi/xiaoxiinfo/queryxx
export const queryxiaoxi = (data) => {
  return http.post('/common/messages',data)
}


//xiaoxi/xiaoxiinfo/queryxxById
export const queryxiaoxibyId = (data) => {
  return http.post('/common/messages',data)
}



export const querydeptbyId = (data) => {
    return http.post('/common/querydeptbyid', data)
}


export const querydeptinfobyphone = (params) => {
    return http.get(`${apiBaseUrl}/dept/deptinfo/querydeptbyPhone?mobilephone=${params}`)
}

///xiaoxi/xiaoxiinfo/updatexx
export const updatexiaoxi = (data) => {
  return http.post(apiBaseUrl + '/xiaoxi/xiaoxiinfo/updatexx',data)
}

//dept/deptinfo/querydeptpage
export const querydeptpage = (data) => {
  return http.post(apiBaseUrl + '/dept/deptinfo/querydeptpage',data)
}


///dept/deptinfo/updateinfo
export const updatedeptinfo = (data) => {
  return http.post(apiBaseUrl + '/dept/deptinfo/updateinfo',data)
}

export const updatedeptinfos = (data) => {
    return http.post('/common/editdept',data)
}

//dept/deptinfo/addinfo
export const adddeptinfo = (data) => {
  return http.post('/common/adddept',data)
}

export const editdeptmobile = (data) => {
  return http.post('/common/editdeptmobile',data)
}

