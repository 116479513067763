<template>
    <div class="cdiv">
        <div class="header flex">
            <div>
                <el-image class="weiziiconst" :src="require('../assets/img/dingwei.png')" fit="cover"></el-image>
            </div>
            <div class="pleft">
                当前位置：<span>首页</span><span>>常用旅客</span><span>>新增</span>
            </div>
        </div>
        <div class="hc flex">

            <el-form size="small" :inline="true" ref="form" :model="form" label-width="80px" class="formstylejp">

                <el-form-item label="" class=" fsizestyle" prop="sendcity">

                    <!-- <span class="spanstyles" v-if="user_type == '1'">
                        <span class="labelspanstyle">部门名称：</span> <el-select class="inputstyle heightstyle" v-model="form.deptname" placeholder="选择部门">
                          <el-option v-for="(item,index) in comdeptdata" :label="item.dpt_name" :value="item.dpt_id"></el-option>
                        </el-select>
                    </span> -->
                    <span class="spanstyles">
                        <span class="labelspanstyle">姓名：</span> <el-input class="inputstyle heightstyle"
                            placeholder="请输入" v-model="form.xingming" clearable></el-input>
                    </span>
                    <span class="spanstyles">
                        <span class="labelspanstyle">手机号码：</span> <el-input class="inputstyle heightstyle"
                            placeholder="请输入" v-model="form.phone" clearable></el-input>
                    </span>
                </el-form-item>

                <el-form-item label="" class=" fsizestyle" prop="xingbie">

                    <span class="spanstyles">
                        <span class="labelspanstyle">性别：</span> <el-select class="inputstyle" v-model="form.xingbie"
                            placeholder="选择">
                            <el-option label="男" value="1"></el-option>
                            <el-option label="女" value="2"></el-option>
                        </el-select>
                    </span>
                    <span class="spanstyles">
                        <span class="labelspanstyle">人员类型：</span> <el-select class="inputstyle" v-model="form.rytype"
                            placeholder="选择">
                            <el-option label="成人" value="1"></el-option>
                            <el-option label="儿童" value="2"></el-option>
                            <el-option label="学生" value="3"></el-option>
                        </el-select>
                    </span>
                </el-form-item>


            </el-form>


        </div>
        <div class="hctwo flex">

            <el-form size="small" :inline="true" ref="form" :model="form" label-width="80px" class="formstylejp">


                <el-form-item label="" class=" fsizestyle" prop="zjleixing">

                    <span class="spanstylestwo">
                        证件类型： <el-select @change="selectzjlx" class="inputstyle" v-model="form.zjleixing" placeholder="选择">
                            <el-option label="身份证" value="1"></el-option>
                            <el-option label="护照" value="2"></el-option>
                        </el-select>
                    </span>
                    <span class="spanstylesthree">
                        证件号码： <el-input class="inputstyle heightstyle" placeholder="请输入" v-model="form.zjnumber"
                            clearable></el-input>
                    </span>

                </el-form-item>
                <el-row>
                    <el-form-item label="" class=" fsizestyle" prop="sendcity">
                        <div class="pcdiv flex">
                            <div>证件照片：</div>
                            <div class="imgzhengmian">
                                <el-upload :show-file-list="false" :fileList="fileList" :on-success="handleSuccessCardZhengmian"
                                    action="http://zdym.bjcaicheng.cn/api/common/upload"
                                    :on-preview="handlePictureCardPreview" list-type="picture-card">
                                    <i class="el-icon-plus"></i>
                                    <img :src="cardZhengmianImgPreview" class="previewimgstyles" v-if="cardzhengmianImg" />
                                </el-upload>
                                
                           

                            </div>
                            <!-- <div class="imgzhengmian" v-if="hzflag==true">
                                <el-upload :fileList="fileList" :before-upload="beforeUpload" :on-success="handleSuccessCardZhengmian"
                                           action="/api/api/train/orderinfo/gethuzhaoshibie"
                                           :on-preview="handlePictureCardPreview" list-type="picture-card" :limit="1">
                                    <i class="el-icon-plus"></i>
                                </el-upload>

                            </div> -->
                            <div class="imgfanmian">
                                <el-upload :show-file-list="false" :fileList="fileList" :on-success="handleSuccessCardFanmian"
                                    action="http://zdym.bjcaicheng.cn/api/common/upload" list-type="picture-card">
                                    <i class="el-icon-plus"></i>
                                    <img :src="cardfanmianImgPreview" class="previewimgstyles" v-if="cardfanmianImg" />
                                </el-upload>
                            </div>
                        </div>
                    </el-form-item>
                </el-row>

            </el-form>


        </div>
        <div class="savebtndiv">
            <el-button class="savebtn" @click="savebtn">提交</el-button>
        </div>


        <div class="copyright">
            工业和信息化部备案管理系统网站 豫B1-20080191-28
        </div>
    </div>
</template>

<script>
import ElImage from "element-ui/packages/image/src/main";
import ElCard from "element-ui/packages/card/src/main";
import ElButton from "element-ui/packages/button/src/button";
import { addpassengerinfo, querycompanybyphone,getComdeptdata } from '../api/index';
import Cookie from 'js-cookie';
export default {
    components: {
        ElButton,
        ElCard,
        ElImage
    },
    data() {
        return {
            userinfo:{},
            hzflag:false,
            activeName: 'first',
            fileList: [],
            danwei: '',
            user_type:'',
            comdeptdata:[],
            form: {
                deptname: '',
                xingming: '',
                phone: '',
                date1: "",
                xingbie: '',
                zjleixing: '',
                zjnumber: '',
                rytype: '',
                date2: "",
                sendcity: "",
                arrivalcity: "",

            },

            cardzhengmianImg: '',
            cardZhengmianImgPreview: '',
            cardfanmianImg: '',
            cardfanmianImggPreview: '',
            rules: {
                mobilephone: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
                ],

                pwd: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                ],

            }
        }
    },
    activated() {
        const userinfo =JSON.parse(Cookie.get('userinfo'));
        this.userinfo=userinfo
        this.danwei=userinfo.gs_id
        this.user_type=userinfo.user_type
        if(this.user_type){
            this.getcomdeptdata();
        }
        //根据手机号查询会员信息
        // this.querymemberbyPhone(user);
        this.fileList = []
        Object.keys(this.form).forEach(key => {
            this.form[key] = ''
        })
        this.cardzhengmianImg="";
        this.cardfanmianImg="";
    },
    methods: {
      // 获取单位部门
      getcomdeptdata(){
          const _this = this
          getComdeptdata().then(res => {
              _this.comdeptdata=res.data.data.find((item) => {
                return item.company_id==_this.danwei
              }).departments;
          })
      },
        //选择证件类型
        selectzjlx(){
            const zjleixingstr=this.form.zjleixing;
            if("1"==zjleixingstr){
                this.hzflag=false;
            }
            if("2"==zjleixingstr){
                this.hzflag=true;
            }
        },
        beforeUpload(){
            const zjleixingstr=this.form.zjleixing;
            if(""==zjleixingstr || null==zjleixingstr){
                this.$message.error('请选择证件类型');
                return false;
            }else{
                return true;
            }
        },
        querymemberbyPhone() {
            this.danwei=userinfo.gs_id
            // const params = { mobilePhone: phonestr };
            // const p = this.$querystring.stringify(params);
            // querycompanybyphone(p).then(res => {
            //     this.danwei = res.data.data.ret[0].danwei;

            // })
        },
        //上传证件照正面
        handleSuccessCardZhengmian(res, file) {
            if(res.code==1){
                this.cardzhengmianImg=res.data.fullurl
                this.cardZhengmianImgPreview=res.data.fullurl
            }else{
                this.$message.error(res.msg)
            }
            return false;
            const zjleixingstr=this.form.zjleixing;
            if("1"==zjleixingstr) {
                //身份证识别
                const {data} = res;
                try {
                    const result = JSON.parse(data.body);
                    const {words_result} = result;
                    const _data = {
                        xingming: words_result['姓名'] ? words_result['姓名'].words : '',
                        zjleixing: '1',
                        zjnumber: words_result['公民身份号码'] ? words_result['公民身份号码'].words : '',
                        xingbie: words_result['性别'] ? words_result['性别'].words : '',
                    }
                    this.form.xingming = _data.xingming;
                    this.form.zjleixing = _data.zjleixing;
                    this.form.zjnumber = _data.zjnumber;
                    this.form.xingbie = _data.xingbie;

                    //this.ruleForm.passengersList.push(_data);
                    this.$message.success('识别成功')
                } catch (error) {
                    console.log("%c Line:409 🍯", "color:#465975", error.message);
                }
            }
            if("2"==zjleixingstr){
                //护照识别
                const { data } = res;
                try {
                    const result = JSON.parse(data.body);
                    const { words_result } = result;
                    console.log("%c Line:491 🌮 words_result", "color:#7f2b82", words_result);
                    const _data = {
                        xingming: words_result['姓名拼音'] ? words_result['姓名拼音'][0].word : '',
                        zjleixing: '2',
                        zjnumber: words_result['护照号'] ? words_result['护照号'][0].word : '',
                        xingbie: words_result['性别'] ? words_result['性别'][0].word : '',
                    }
                    this.form.xingming = _data.xingming;
                    this.form.zjleixing = _data.zjleixing;
                    this.form.zjnumber = _data.zjnumber;
                    this.form.xingbie = _data.xingbie;
                    this.$message.success('识别成功')
                } catch (error) {
                    console.log("%c Line:409 🍯", "color:#465975", error.message);
                }
            }
            this.cardzhengmianImg = res.data.ret;

        },
        //图片预览
        handlePictureCardPreview(file) {

            this.cardZhengmianImgPreview = file.url;
        },
        //上传证件照反面
        handleSuccessCardFanmian(res, file) {
            if(res.code==1){
                this.cardfanmianImg=res.data.fullurl
                this.cardfanmianImgPreview=res.data.fullurl
            }else{
                this.$message.error(res.msg)
            }

        },
        validateSfz(value) {
            if (!value || value.length !== 18) {
                // this.$message.error('请输入正确的身份证号');
                return false
            }
            return true
        },
        savebtn() {
            const user = this.userinfo.id;
            const danweistr = this.danwei;
            var deptnamestr = this.form.deptname;
            const xingmingstr = this.form.xingming;
            const phonestr = this.form.phone;
            const xingbiestr = this.form.xingbie;
            const rytypestr = this.form.rytype;
            const idtypestr = this.form.zjleixing;
            const zjnumstr = this.form.zjnumber;
            const cardzmingstr = this.cardzhengmianImg;
            const cardimgfanmianstrs =this.cardfanmianImg;
            const cdept=Cookie.get('deptKey');
            if(""!=cdept && null!=cdept){
                deptnamestr=cdept;
            }
            var xingbieval="";
            if("男"==xingbiestr){
                xingbieval="1";
            }
            if("女"==xingbiestr){
                xingbieval="2";
            }
            if ("" == xingmingstr || null == xingmingstr) {
                this.$message.warning('请输入姓名');
                return;
            }
            if ("" == phonestr || null == phonestr) {
                this.$message.warning('请输入手机号');
                return;
            }
            if (!(/^1(3|4|5|7|8|9|6)\d{9}$/i.test(phonestr))) {
                this.$message.warning('手机号错误');
                return;
            }
            if ("" == xingbiestr || null == xingbiestr) {
                this.$message.warning('请选择性别');
                return;
            }
            if ("" == rytypestr || null == rytypestr) {
                this.$message.warning('请选择人员类型');
                return;
            }
            if ("" == zjnumstr || null == zjnumstr) {
                this.$message.warning('请输入证件号码');
                return;
            }
            if ("1" == idtypestr && !this.validateSfz(zjnumstr)) {
                this.$message.warning('身份证号码错误');
                return;
            }
            if ("" == cardzmingstr || null == cardzmingstr) {
                this.$message.warning('请上传身份证正面');
                return;
            }
            if ("" == cardimgfanmianstrs || null == cardimgfanmianstrs) {
                this.$message.warning('请上传身份证反面');
                return;
            }

            const params = {
                xingming: xingmingstr,
                pg_phone: phonestr,
                xingbie: xingbiestr,
                person_type: rytypestr,
                id_type: idtypestr,
                id_number: zjnumstr,
                id_img_zhengmian: cardzmingstr,
                id_img_fanmian:cardimgfanmianstrs,
                mobile_phone: user
            }
            addpassengerinfo(params).then(res => {
                if (res.data.code && res.data.code === 1) {
                    this.$message.success('添加成功');
                    this.$router.push('/passenger');
                } else {
                    var msgstr = res.data.msg;
                    this.$message.error(msgstr);

                }
            })
        }
    },
    name: "passengeradd-right"
}
</script>

<style scoped>
/deep/ .el-upload{position: relative;}
/deep/ .el-upload img{position: absolute;width: 100%;height: 100%;left: 0;top: 0;object-fit: contain;}
.previewimgstyles {
    width: 100%;
    height: 100%;
}

.labelspanstyle {
    display: inline-block;
    width: 70px;
    margin-left: 10px;
}

.pcdiv {
    padding-top: 1vh;
    margin-left: 20px;
}

.imgzhengmian {}

.imgfanmian {
    margin-left: 1vw;
}

.savebtndiv {
    width: calc(88% - 0px - 10px) !important;
    margin-top: 3vh;
    text-align: center;


}

.savebtn {
    background-color: #42B134;
    color: #FFFFFF;
    width: 400px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.spanstylestwo {
    width: auto;
    display: inline-block;
    margin-left: 20px;

}

.spanstylesthree {
    width: auto;
    display: inline-block;
    margin-left: 12px;
    margin-right: 20px;

}

.spanstyles {
    width: auto;
    display: inline-block;
    margin-left: 10px;
}

.tcontentwidth {
    width: calc(60% - 0px - 0px) !important;
}

.rightoper {
    margin-top: 30px;
}

.shuxian {
    height: 180px;
    font-size: 1px;
    width: 1px;
    border-right: 1px dashed rgba(66, 177, 52, 1);

    margin-left: 30px;
}

.defaultbtn {
    background-color: #42B134;
    color: #FFFFFF;
}

.hcpdiv {
    margin-top: 2vh;
    background-color: #F1F1F1;
    width: 200px;
    padding-top: 2vh;
    padding-left: 1.6vw;
    border-top-right-radius: 20px;
    border-top-left-radius: 6px;

}

.titlecolor {}

.xiancolor {
    color: rgba(66, 177, 52, 1);
}

.hcpicon {
    width: 20px;
    height: 27px;
}

.dfkcolor {
    color: rgba(100, 100, 100, 1);
}

.mleft {
    margin-left: 16px;
}

.titlecolor {
    color: rgba(66, 177, 52, 1);
}

.mttop {
    margin-top: 1vh;
}

.weiziiconst {
    width: 24px;
    height: 24px;
}

.inputstyle {
    width: 300px;
}

.cdiv {}

.xiadandate {
    color: #646464;
    font-size: 12px;
    margin-left: 10vw;
}

.xiadaninfo {
    margin-left: 1.8vw;
    margin-top: 3vh;
    color: #646464;
    font-size: 12px;

}

.mtopstyle {
    margin-left: 1vw;
    margin-top: 1vh;
}

.hengxian {
    border-bottom: 1px solid rgba(34, 172, 56, 1);
    margin-left: 2vw;
}

.paycolor {
    color: #42B134;
    border: 1px solid #42B134;
    width: 100px;
    height: 35px;
    text-align: center;
}

.mstyle {
    width: 100px;
    text-align: center;

}

.operbtn {
    margin-left: 30px;
    margin-top: 8vh;

}

.redcolorstyle {
    color: #FF7342;
    font-weight: bolder;
}

.bluecolor {
    color: #22AC38;
}

.sficon {
    margin-left: 0.5vw;
}

.tabwidth {
    width: calc(88% - 0px - 10px) !important;
    background-color: #F1F1F1;
    margin-top: 3vh;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.weiziiconsj {
    width: 16px;
    height: 16px;
    padding-left: 1.2vw;
}

.iconleft {
    margin-left: 0.5vw;
    margin-top: 0.2vh;
}

.datestrs {
    margin-left: 0.5vw;
}

.traindatediv {
    background-color: #F1F1F1;
    width: calc(88% - 0px - 10px) !important;
    border-top-right-radius: 20px;
    padding-top: 20px;

}

.copyright {
    width: calc(88% - 0px - 10px) !important;
    font-size: 12px;
    text-align: center;
    margin-top: 5vh;
    color: #B6B6B6;
}

.mleftmargin {
    margin-left: 1vw;
}

.pager {
    margin-top: 5vh;
    margin-left: 0vw;
    width: calc(88% - 0px - 10px) !important;
    display: flex;
    justify-content: center;

}

.shouye {}

.sybtn {
    width: 100px;
    height: 35px;
    border: 1px solid #666666;
    text-align: center;
}

.btnnodiv {
    margin-left: 1vw;
}

.btnno {
    width: 50px;
    height: 35px;
    border: 1px solid #666666;
    text-align: center;
}

.commonmtop {
    margin-top: 2vh;
}

.btnmargin {
    margin-top: 1vh;
}

.btnstyle {
    width: 100px;
    height: 35px;
    background-color: #42B134;
    color: #FFFFFF;
    border-radius: 5px;
}

.checizuowei {
    margin-left: 5vw;
}

.colorstyle {
    color: #F49200;
    font-weight: bolder;
}

.checileixing {
    margin-left: 5vw;
}

.checimoney {
    margin-left: 5vw;
}

.zwleixing {
    margin-top: 0.1vh;
}

.cmtopzw {
    margin-top: 2vh;
}

.cxinfo {
    margin-left: 2vw;
}

.endcheci {
    margin-left: 3vw;
}

.sleft {
    margin-left: 1vw;
}

.scheci {
    margin-left: 2vw;

}

.startzhan {
    margin-left: 1vw;
}

.scdiv {
    margin-top: 2vh;
}

.sccdate {
    font-size: 20px;
    font-weight: bolder;
    margin-left: 1vw;
}

.haoshi {
    margin-left: 3vw;
}

.tcontent {
    background-color: #F1F1F1;
    height: 180px;
    width: calc(88% - 0px - 10px) !important;
    margin-top: -1vh;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

.mleftd {
    margin-left: -2vw;
}

.querybtn {
    background-color: #42B134;
    color: #FFFFFF;
    border-radius: 5px;
    margin-left: 1vw;
    width: 100px;
}

.trainorderstyle {
    margin-left: 1vw;
}

.formstyle {
    padding-top: 2.2vh;
}

.container {}

.header {
    font-size: 12px;
}

.flex {
    display: flex;
}

.weiziicon {}

.pleft {
    margin-left: 0.5vw;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
}

.hctwo {
    background-color: #F1F1F1;
    height: 240px;
    margin-top: 2vh;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.hc {
    background-color: #F1F1F1;
    margin-top: 2vh;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.formstylejp {
    padding-top: 2.2vh;
}

button:hover {
    background-color: #42B134;
    color: #FFFFFF;
}
</style>
