import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue';
import Home from '../views/Home.vue';
import User from '../views/User.vue';
import Page1 from '../views/Page1.vue';
import Page2 from '../views/Page2.vue';
import Login from '../views/Login.vue';
import Forgetpwd from '../views/Forgetpwd.vue';
import Pageindex from '../views/Pageindex.vue';
import Trainyuding from '../views/Trainyuding.vue';
import Airticketyuding from '../views/Airticketyuding.vue';
import Trainorder from '../views/Trainorder.vue';
import Airticketorder from '../views/Airticketorder.vue';
import Accountmingxi from '../views/Accountmingxi.vue';
import Passenger from '../views/Passenger.vue';
import Gerenzx from '../views/Gerenzx.vue';
import Gonggaolist from '../views/Gonggaolist.vue';
import Gonggaodetails from '../views/Gonggaodetails.vue';
import Trainyudingshenqing from '../views/Trainyudingshenqing.vue';
import Airticketyudingshenqing from '../views/Airticketyudingshenqing.vue';
import Trainorderdetails from '../views/Trainorderdetails.vue';
import Airticketorderdetails from '../views/Airticketorderdetails.vue'
import Updateziliao from '../views/Updateziliao.vue'
import Passengeradd from '../views/Passengeradd.vue'
import Passengerupdate from '../views/Passengerupdate.vue'
import Trainorderdetailstwo from '../views/Trainorderdetailstwo.vue'
import Airticketorderdetailstwo from '../views/Airticketorderdetailstwo.vue'
import Jiesuandan from '../views/Jiesuandan.vue'
import Renyuandaishenhe from '../views/Renyuandaishenhe.vue'
import Adddept from '../views/Adddept.vue'
import Updatedept from '../views/Updatedept.vue'
import Addemp from '../views/Addemp.vue'
import Updateemp from '../views/Updateemp.vue'
import Xiaoxidetails from '../views/Xiaoxidetails.vue'
import Airticketgaiqiantuipiao from '../views/Airticketgaiqiantuipiao.vue'
import Traingaiqiantuipiao from '../views/Traingaiqiantuipiao.vue'
import Traingaiqian from '../views/Traingaiqian.vue'

import Cookies from 'js-cookie';

Vue.use(VueRouter)

const routes = [{
    path: '/',
    component: Main,
    redirect: '/home',
    children: [{
        path: '/home',
        component: Home
      },
      {
        path: '/user',
        component: User
      },
      {
        path: '/page1',
        name: 'page1',
        component: Page1
      },
      {
        path: '/page2',
        name: 'page2',
        component: Page2
      }
    ],

    meta: {
      keepAlive: true,
      title: ''
     }
  },

  {
    path: '/login',
    name: 'login',
    component: Login,
  },
    {
        path: '/forgetpwd',
        name: 'forgetpwd',
        component: Forgetpwd,
    },
    {
        path: '/pageindex',
        name: 'pageindex',
        component: Pageindex,
    },
    {
        path: '/trainyuding',
        name: 'trainyuding',
        component: Trainyuding,
    },
    {
        path: '/airticketyuding',
        name: 'airticketyuding',
        component: Airticketyuding,
    },
    {
        path: '/trainorder',
        name: 'trainorder',
        component: Trainorder,
    },
    {
        path: '/airticketorder',
        name: 'airticketorder',
        component: Airticketorder,
    },
    {
        path: '/accountmingxi',
        name: 'accountmingxi',
        component: Accountmingxi,
    },
    {
        path: '/passenger',
        name: 'passenger',
        component: Passenger,
    },
    {
        path: '/gerenzx',
        name: 'gerenzx',
        component: Gerenzx,
    },
    {
        path: '/gonggaolist',
        name: 'gonggaolist',
        component: Gonggaolist,
    },
    {
        path: '/gonggaodetails',
        name: 'gonggaodetails',
        component: Gonggaodetails,
    },
    {
        path: '/trainyudingshenqing',
        name: 'trainyudingshenqing',
        component: Trainyudingshenqing,
    },
    {
        path: '/airticketyudingshenqing',
        name: 'airticketyudingshenqing',
        component: Airticketyudingshenqing,
    },
    {
        path: '/trainorderdetails',
        name: 'trainorderdetails',
        component: Trainorderdetails,
    },
    {

        path: '/airticketorderdetails',
        name: 'airticketorderdetails',
        component: Airticketorderdetails,
    },
    {
        path: '/updateziliao',
        name: 'updateziliao',
        component: Updateziliao,

    },
    {
        path: '/passengeradd',
        name: 'passengeradd',
        component: Passengeradd,

    },
    {
        path: '/passengerupdate',
        name: 'passengerupdate',
        component: Passengerupdate,

    },
    {

        path: '/trainorderdetailstwo',
        name: 'trainorderdetailstwo',
        component: Trainorderdetailstwo,
    },
    {
        path: '/airticketorderdetailstwo',
        name: 'airticketorderdetailstwo',
        component: Airticketorderdetailstwo,
    },{

        path: '/jiesuandan',
        name: 'jiesuandan',
        component: Jiesuandan,
    },{

      path: '/renyuandaishenhe',
       name: 'renyuandaishenhe',
       component: Renyuandaishenhe,
    }
    ,{

        path: '/adddept',
        name: 'adddept',
        component: Adddept,
    },
    {

        path: '/updatedept',
        name: 'updatedept',
        component: Updatedept,
    },
    {

        path: '/addemp',
         name: 'addemp',
         component: Addemp,
    },
    {

        path: '/updateemp',
        name: 'updateemp',
        component: Updateemp,
    },
    {

        path: '/xiaoxidetails',
        name: 'xiaoxidetails',
        component: Xiaoxidetails,
    },
    {
        path: '/airticketgaiqiantuipiao',
        name: 'airticketgaiqiantuipiao',
        component: Airticketgaiqiantuipiao,
    },
    {

        path: '/traingaiqiantuipiao',
        name: 'traingaiqiantuipiao',
       component: Traingaiqiantuipiao,
    },
    {

        path: '/traingaiqian',
        name: 'traingaiqian',
       component: Traingaiqian,
    }
]

const router = new VueRouter({
  routes, // (缩写) 相当于 routes: routes
})

// router.beforeEach((to, from, next) => {
//   // 判断token存不存在
//   const token = Cookies.get('token');
//   // token不存在
//   if (!token && to.name !== 'login') {
//     next({
//       name: 'login'
//     })
//   } else if (token && to.name === 'login') {
//     next({
//       name: 'home'
//     })
//   } else {
//     next()
//   }
// });
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err)
}
export default router